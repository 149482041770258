import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Table from 'react-bootstrap/Table';
const Forms = (response: any = [], mode: any = 'new') => {

    let dCustomer = <></>;

    const show = (e:any) => {
        if(mode === 'new') {
            if(e.target.value === 'sonstiges') {
                (document.getElementById("dynamik") as HTMLDivElement).style.display = "block";
                (document.getElementById("customer") as HTMLDivElement).style.display = "none";
                (document.getElementById("company") as HTMLDivElement).style.display = "none";
            } else {
                (document.getElementById("dynamik") as HTMLDivElement).style.display = "none";
                (document.getElementById("customer") as HTMLDivElement).style.display = "block";
                (document.getElementById("company") as HTMLDivElement).style.display = "block";
            }
        } else {

        }

    }

    if(mode !== 'new' && response[0].type === 'sonstiges' || localStorage.getItem('BillType') === 'sonstiges') {

        dCustomer = <><Row>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Anrede:</Form.Label><Form.Control
                type="text"
                name={'dtitle'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dtitle
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Firma:</Form.Label><Form.Control
                type="text"
                name={'dcompany'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dcompany
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Vorname:</Form.Label><Form.Control
                type="text"
                name={'dfirstname'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dfirstname
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Nachname:</Form.Label><Form.Control
                type="text"
                name={'dlastname'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dlastname
                }
            /> </Form.Group></Col></Row>
        <Row>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Straße:</Form.Label><Form.Control
                type="text"
                name={'dstreet'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dstreet
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>PLZ:</Form.Label><Form.Control
                type="text"
                name={'dzip'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dzip
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Ort:</Form.Label><Form.Control
                type="text"
                name={'dcity'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dcity
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Land:</Form.Label><Form.Control
                type="text"
                name={'dcountry'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dcountry
                }
            /> </Form.Group></Col>
        </Row>
        <Row>
            <Col>
                <Table striped bordered hover >
                    <thead>
                    <th>Pos</th>
                    <th>Bezeichnung</th>
                    <th>Preis</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td><Form.Control as="textarea" name={'pos1description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].pos1description
                        } /></td>
                        <td><Form.Control
                            type="text"
                            name={'pos1price'}
                            required={true}
                            readOnly={(mode === 'new' ? false : true)}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos1price
                            }
                        /></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><Form.Control as="textarea" name={'pos2description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].pos2description
                        } /></td>
                        <td><Form.Control
                            type="text"
                            name={'pos2price'}
                            required={true}
                            readOnly={(mode === 'new' ? false : true)}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos2price
                            }
                        /></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><Form.Control as="textarea" name={'pos3description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                            mode === 'new'
                                ? ''
                                : response[0].pos3description
                        } /></td>
                        <td><Form.Control
                            type="text"
                            name={'pos3price'}
                            required={true}
                            readOnly={(mode === 'new' ? false : true)}
                            defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos3price
                            }
                        /></td>
                    </tr>
                    </tbody>
                </Table>
            </Col>
        </Row></>;
    } else {
        dCustomer = <><div  id={'dynamik'} style={{display:'none'}}><Row>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Anrede:</Form.Label><Form.Control
                type="text"
                name={'dtitle'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dtitle
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Firma:</Form.Label><Form.Control
                type="text"
                name={'dcompany'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dcompany
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Vorname:</Form.Label><Form.Control
                type="text"
                name={'dfirstname'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dfirstname
                }
            /> </Form.Group></Col>
            <Col><Form.Group
                className="mb-3"
                controlId="addressId"
            ><Form.Label>Nachname:</Form.Label><Form.Control
                type="text"
                name={'dlastname'}
                required={true}
                readOnly={(mode === 'new' ? false : true)}
                defaultValue={
                    mode === 'new'
                        ? ''
                        : response[0].dlastname
                }
            /> </Form.Group></Col></Row>
            <Row>
                <Col><Form.Group
                    className="mb-3"
                    controlId="addressId"
                ><Form.Label>Straße:</Form.Label><Form.Control
                    type="text"
                    name={'dstreet'}
                    required={true}
                    readOnly={(mode === 'new' ? false : true)}
                    defaultValue={
                        mode === 'new'
                            ? ''
                            : response[0].dstreet
                    }
                /> </Form.Group></Col>
                <Col><Form.Group
                    className="mb-3"
                    controlId="addressId"
                ><Form.Label>PLZ:</Form.Label><Form.Control
                    type="text"
                    name={'dzip'}
                    required={true}
                    readOnly={(mode === 'new' ? false : true)}
                    defaultValue={
                        mode === 'new'
                            ? ''
                            : response[0].dzip
                    }
                /> </Form.Group></Col>
                <Col><Form.Group
                    className="mb-3"
                    controlId="addressId"
                ><Form.Label>Ort:</Form.Label><Form.Control
                    type="text"
                    name={'dcity'}
                    required={true}
                    readOnly={(mode === 'new' ? false : true)}
                    defaultValue={
                        mode === 'new'
                            ? ''
                            : response[0].dcity
                    }
                /> </Form.Group></Col>
                <Col><Form.Group
                    className="mb-3"
                    controlId="addressId"
                ><Form.Label>Land:</Form.Label><Form.Control
                    type="text"
                    name={'dcountry'}
                    required={true}
                    readOnly={(mode === 'new' ? false : true)}
                    defaultValue={
                        mode === 'new'
                            ? ''
                            : response[0].dcountry
                    }
                /> </Form.Group></Col>
            </Row>
            <Row>
                <Col>
                    <Table striped bordered hover >
                        <thead>
                        <th>Pos</th>
                        <th>Bezeichnung</th>
                        <th>Preis</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td><Form.Control as="textarea" name={'pos1description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos1description
                            } /></td>
                            <td><Form.Control
                                type="text"
                                name={'pos1price'}
                                required={true}
                                readOnly={(mode === 'new' ? false : true)}
                                defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].pos1price
                                }
                            /></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td><Form.Control as="textarea" name={'pos2description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos2description
                            } /></td>
                            <td><Form.Control
                                type="text"
                                name={'pos2price'}
                                required={true}
                                readOnly={(mode === 'new' ? false : true)}
                                defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].pos2price
                                }
                            /></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td><Form.Control as="textarea" name={'pos3description'} rows={1} disabled={(mode === 'new' ? false : true)} defaultValue={
                                mode === 'new'
                                    ? ''
                                    : response[0].pos3description
                            } /></td>
                            <td><Form.Control
                                type="text"
                                name={'pos3price'}
                                required={true}
                                readOnly={(mode === 'new' ? false : true)}
                                defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].pos3price
                                }
                            /></td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div></>;
    }

    return (
        <>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                key={'tabindex'}

            >
                <Tab
                    eventKey="home"
                    title={'Rechnung'}
                    className="bg-white"
                    key={'Rechnung'}
                    id={'Rechnung'}
                >
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="shareCapital"
                            >
                                <Form.Label>
                                    Nummer
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'invoiceNr'}
                                    required={true}
                                    readOnly={(mode === 'new' ? false : true)}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].invoiceNr
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="shareCapital"
                            >
                                <Form.Label>
                                    Datum
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name={'date'}
                                    placeholder={'Wird automatisch vergeben!'}
                                    required={true}
                                    readOnly={true}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].date
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="companyName"
                            >
                                <Form.Label>Type:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'type'}
                                    onClick={(e)=>show(e)}
                                    defaultValue={
                                        mode === 'new'
                                            ? ''
                                            : response[0].type
                                    }
                                    disabled={(mode === 'new' ? false : true)}
                                >
                                    <option value={'rechnung'}>Rechnung</option>
                                    <option value={'provision'}>Provision</option>
                                    <option value={'sonstiges'}>Sonstiges</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col id={'customer'} className={(mode !== 'new' && response[0].type === 'sonstiges' ? 'hidden' : '')}>
                            <Form.Group
                                className="mb-3"
                                controlId="addressId"
                            >
                                <Form.Label>Kundenwahl:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'addressId'}
                                    disabled={(mode === 'new' ? false : true)}
                                >
                                   <option value={(mode === 'new' ? '' : response[0].addressId)}>
                                       {(mode === 'new' || response[0].type === 'sonstiges' ? '' :
                                           response[0].address.first_name +' '+
                                       response[0].address.last_name)
                                   }

                                   </option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col id={'company'} className={(mode !== 'new' && response[0].type === 'sonstiges' ? 'hidden' : '')}>
                            <Form.Group
                                className="mb-3"
                                controlId="addressId"
                            >
                                <Form.Label>Firmenwahl:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'businessNumberFromList'}
                                    disabled={(mode === 'new' ? false : true)}
                                >
                                    <option>{(mode === 'new' ? '' :(response[0].ewiv === '' ? response[0].sro : response[0].ewiv))}</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="addressId"
                            >
                                <Form.Label>Quartal:</Form.Label>
                                <Form.Select
                                    aria-label="Default select example"
                                    name={'quarter'}
                                    defaultValue={
                                        mode === 'new'
                                            ? '2023'
                                            : response[0].quarter
                                    }
                                >
                                    <option value={'1'}>1</option>
                                    <option value={'2'}>2</option>
                                    <option value={'3'}>3</option>
                                    <option value={'4'}>4</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="farewell"

                            >
                                <Form.Label>Jahr:</Form.Label>
                                <Form.Control min="1900" max="2099" step="1" type={'number'} name={'year'} defaultValue={
                                    mode === 'new'
                                        ? '2023'
                                        : response[0].year
                                }/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="recipients"
                            >
                                <Form.Label>Empfänger E-Mail:</Form.Label>
                                <Form.Control type={'email'} name={'recipients'}
                                              readOnly={(mode === 'new' ? false : true)}
                                              defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].recipients
                                }/>
                            </Form.Group>
                        </Col>
                    </Row>
                    {dCustomer}
                    <Row>
                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="farewell"
                            >
                                <Form.Label>Betreff:</Form.Label>
                                <Form.Control as="textarea" name={'subject'} rows={3} defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].subject
                                }/>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="farewell"
                            >
                                <Form.Label>E-Mail-Text:</Form.Label>
                                <Form.Control as="textarea" name={'greeting'} rows={3} defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].greeting
                                }/>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group
                                className="mb-3"
                                controlId="greeting"
                            >
                                <Form.Label>Abschlusssatz:</Form.Label>
                                <Form.Control as="textarea" rows={3} name={'farewell'} defaultValue={
                                    mode === 'new'
                                        ? ''
                                        : response[0].farewell
                                } />
                            </Form.Group>
                        </Col>
                    </Row>

                </Tab>

            </Tabs>
        </>
    )
}

export default Forms

const unlock = (e: any, name: any) => {
    let fileField = document.getElementById(name) as HTMLInputElement
    if (
        e.target.value !== undefined &&
        e.target.value !== null &&
        e.target.value !== ''
    ) {
        fileField.removeAttribute('disabled')
    } else {
        fileField.setAttribute('disabled', 'true')
    }
}
